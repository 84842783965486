import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

import variables from './variables';
import utils from './utils';
import '../app.css';


export default createGlobalStyle`
  ${normalize}
  ${variables}
  ${utils}
  body {
    margin: 0;
    font-size: 16px;
    font-family: 'Assistant', sans-serif;
    font-weight: 300;
    background-color: #f0f2f5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--primary);
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-weight: 300;
  }


  * {
    box-sizing: border-box;

    /* width */

    ::-webkit-scrollbar {
      width: 6px;
      height: 6px
    }


    /* Track */

    ::-webkit-scrollbar-track {
      background: var(--border-color);
    }

    /* Handle */

    ::-webkit-scrollbar-thumb {
      background: var(--fade);
      border-radius: 5px;
    }

    /* Handle on hover */

    ::-webkit-scrollbar-thumb:hover {

    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`;
