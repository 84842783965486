import { createSlice } from '@reduxjs/toolkit';


const storeName = 'channelView';

const initialState = {
  selectedMobileTab: 0,
  channel: null,
  isChannelLoaded: false,
  isRefreshData: false,
  roleSlug: '',
  isEditTitle: false,
  isSaveTitle: false,
  isEditNotes: false,
  isSaveNotes: false,
  meetings: [],
  completedMeetings: [],
  tasks: [],
  completedTasks: [],
  uploadedDocuments: [],
  isOpenParticipantsModal: false,
  modalState: { open: false },
  isOpenTasksAccordion: false,
  isOpenMeetingsAccordion: false,
  isOpenDocumentsAccordion: false,
  documentsTableView: 'grid',
};

export const channelViewSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setSelectedMobileTab(state, action) {
      state.selectedMobileTab = action.payload;
    },
    setChannel(state, action) {
      state.channel = action.payload;
    },
    setIsChannelLoaded(state, action) {
      state.isChannelLoaded = action.payload;
    },
    setIsRefreshData(state, action) {
      state.isRefreshData = action.payload;
    },
    setRoleSlug(state, action) {
      state.roleSlug = action.payload;
    },
    setIsEditTitle(state, action) {
      state.isEditTitle = action.payload;
    },
    setIsSaveTitle(state, action) {
      state.isSaveTitle = action.payload;
    },
    setIsEditNotes(state, action) {
      state.isEditNotes = action.payload;
    },
    setIsSaveNotes(state, action) {
      state.isSaveNotes = action.payload;
    },
    setMeetings(state, action) {
      state.meetings = action.payload;
    },
    setCompletedMeetings(state, action) {
      state.completedMeetings = action.payload;
    },
    setTasks(state, action) {
      state.tasks = action.payload;
    },
    setCompletedTasks(state, action) {
      state.completedTasks = action.payload;
    },
    setUploadedDocuments(state, action) {
      state.uploadedDocuments = action.payload;
    },
    setIsOpenParticipantsModal(state, action) {
      state.isOpenParticipantsModal = action.payload;
    },
    setModalState(state, action) {
      state.modalState = action.payload;
    },
    setIsOpenNotesAccordion(state, action) {
      state.isOpenNotesAccordion = action.payload;
    },
    setIsOpenTasksAccordion(state, action) {
      state.isOpenTasksAccordion = action.payload;
    },
    setIsOpenMeetingsAccordion(state, action) {
      state.isOpenMeetingsAccordion = action.payload;
    },
    setIsOpenDocumentsAccordion(state, action) {
      state.isOpenDocumentsAccordion = action.payload;
    },
    setDocumentsTableView(state, action) {
      state.documentsTableView = action.payload;
    },
    reset: () => initialState
  },
});

export const channelViewActions = {
  setSelectedMobileTab: channelViewSlice.actions.setSelectedMobileTab,
  setChannel: channelViewSlice.actions.setChannel,
  setIsChannelLoaded: channelViewSlice.actions.setIsChannelLoaded,
  setIsRefreshData: channelViewSlice.actions.setIsRefreshData,
  setRoleSlug: channelViewSlice.actions.setRoleSlug,
  setIsEditTitle: channelViewSlice.actions.setIsEditTitle,
  setIsSaveTitle: channelViewSlice.actions.setIsSaveTitle,
  setIsEditNotes: channelViewSlice.actions.setIsEditNotes,
  setIsSaveNotes: channelViewSlice.actions.setIsSaveNotes,
  setMeetings: channelViewSlice.actions.setMeetings,
  setCompletedMeetings: channelViewSlice.actions.setCompletedMeetings,
  setTasks: channelViewSlice.actions.setTasks,
  setCompletedTasks: channelViewSlice.actions.setCompletedTasks,
  setUploadedDocuments: channelViewSlice.actions.setUploadedDocuments,
  setIsOpenParticipantsModal: channelViewSlice.actions.setIsOpenParticipantsModal,
  setModalState: channelViewSlice.actions.setModalState,
  setIsOpenNotesAccordion: channelViewSlice.actions.setIsOpenNotesAccordion,
  setIsOpenTasksAccordion: channelViewSlice.actions.setIsOpenTasksAccordion,
  setIsOpenMeetingsAccordion: channelViewSlice.actions.setIsOpenMeetingsAccordion,
  setIsOpenDocumentsAccordion: channelViewSlice.actions.setIsOpenDocumentsAccordion,
  setDocumentsTableView: channelViewSlice.actions.setDocumentsTableView,
  reset: channelViewSlice.actions.reset,
};

export const channelViewReducer = channelViewSlice.reducer;
