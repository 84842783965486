import axios from 'axios';


const BASE_URL = process.env.BASE_URL ?? 'https://api.llabo.co';

// endpoints
export const LOGIN_URL = `${BASE_URL}/api/auth/login`;
export const LOGOUT_URL = `${BASE_URL}/api/auth/logout`;
export const SMS_URL = `${BASE_URL}/api/auth/verify`;
export const USER_URL = `${BASE_URL}/api/user`;
export const USER_COUNTRY = `${BASE_URL}/api/user/country`;
export const USER_BY_ID_URL = (id) => `${BASE_URL}/api/user/${id}`;
export const ADD_MEETING_URL = `${BASE_URL}/api/meeting`;
export const LIST_MEETING_URL = `${BASE_URL}/api/business/meetings`;
export const MEETING_BY_ID = (id) => `${BASE_URL}/api/meeting/${id}`;

export const USER_MEETINGS_BY_ID = (id) =>
  `${BASE_URL}/api/business/user-meetings/${id}`;
export const USERS_URL = `${BASE_URL}/api/users`;
export const USERS_BY_ID_URL = (id) => `${BASE_URL}/api/users/${id}`;
export const MEDIA_URL = `${BASE_URL}/api/media/groups`;
export const ADD_MEDIA_URL = `${BASE_URL}/api/media`;
export const MODIFY_MEDIA_BY_ID_URL = (id) => `${BASE_URL}/api/media/${id}`;
export const MEDIA_BY_ID_URL = (id) => `${BASE_URL}/api/media/groups/${id}`;
export const MEDIA_DOCUMENTS_LIST = `${BASE_URL}/api/media/documents?type=consent_form`;
export const REMINDER_GROUP_URL = `${BASE_URL}/api/reminder/groups`;
export const REMINDER_URL = `${BASE_URL}/api/reminder`;
export const REMINDER_BY_ID = (id) => `${BASE_URL}/api/reminder/groups/${id}`;
export const REMINDER_ITEM_BY_ID = (id) => `${BASE_URL}/api/reminder/${id}`;
export const DEFAULT_MEDIA_BY_ID = `${BASE_URL}/api/media/default`;
export const REMINDER_MEDIA_BY_ID = (id, groupId) =>
  `${BASE_URL}/api/reminder/groups/${id}/media-group/${groupId}`;
export const LANGUAGES_URL = `${BASE_URL}/api/languages`;
export const SEARCH_USER_URL = `${BASE_URL}/api/users/search`;

// export const SIGNUP_URL = `${BASE_URL}/api/business/join`;
export const SIGNUP_URL = `${BASE_URL}/api/auth/signup`;
export const BUSINESS_URL = `${BASE_URL}/api/business/`;
export const BUSINESSES_URL = `${BASE_URL}/api/business/list`;
export const BUSINESS_TEAM_URL = `${BASE_URL}/api/business/team`;
export const BILLING_PLANS = `${BASE_URL}/api/billing/plans`;

export const BUSINESS_BILLING_PLAN = `${BASE_URL}/api/business/billing-plan`;
export const CONTACT_US_URL = `${BASE_URL}/api/support/ticket`;
export const SESSION_BY_ID = (id) => `${BASE_URL}/api/session/${id}`;
export const ACCEPT_SESSION_TERMS_BY_ID = (id) =>
  `${BASE_URL}/api/session/${id}/accept-terms`;
export const START_SESSION_BY_ID = (id) =>
  `${BASE_URL}/api/session/${id}/start`;
export const INIT_SESSION_BY_ID = (id) => `${BASE_URL}/api/session/${id}/init`;
export const STOP_SESSION_BY_ID = (id) => `${BASE_URL}/api/session/${id}/stop`;
export const COMPLETE_SESSION_BY_ID = (id) => `${BASE_URL}/api/session/${id}/complete`;
export const SEND_SIGN_REQUEST = (id) => `${BASE_URL}/api/session/${id}/sign`;
export const SIGN_REQUEST_COMPLETED = (id) =>
  `${BASE_URL}/api/session/${id}/sign-completed`;
export const DOWNLOAD_SIGNED_DOCS = (id) =>
  `${BASE_URL}/api/session/${id}/signed-docs`;

export const GET_VIDEO = (id, download) =>
  `${BASE_URL}/api/session/${id}/get-video/?download=${download}`;

export const SEND_MESSAGE = (id) => `${BASE_URL}/api/session/${id}/messaging`;
export const DELETE_MESSAGE = (id, messageID) =>
  `${BASE_URL}/api/channel/${id}/messaging/${messageID}`;
export const UPLOAD_FILES = (id) => `${BASE_URL}/api/session/${id}/files`;
export const UPLOAD_MEDIA_FILES = `${BASE_URL}/api/media/documents/files`;

export const SEND_CHANNEL_MESSAGE = (id) =>
  `${BASE_URL}/api/channel/${id}/messaging`;
export const UPLOAD_CHANNEL_FILES = (id) =>
  `${BASE_URL}/api/channel/${id}/files`;
//Roles
export const ROLES = `${BASE_URL}/api/roles`;

//Notifications
export const READ_NOTIFICATION = (userId, id) => `${BASE_URL}/user_activity/${userId}/notification/${id}`;
export const NOTIFICATIONS = (userId) => `${BASE_URL}/user_activity/${userId}/notifications`;


//Channels
export const CHANNELS = `${BASE_URL}/api/channels`;
export const CHANNEL = (id) => `${BASE_URL}/api/channel/${id}`;
export const CREATE_CHANNEL = (id) =>
  `${BASE_URL}/api/organizations/${id}/channels`;
export const CHANNEL_PING = (id) => `${BASE_URL}/api/channel/${id}/ping`;
export const CHANNEL_MEMBER = (id, memberId) =>
  `${BASE_URL}/api/channel/${id}/members/${memberId}`;
export const CHANNEL_TASKS = (id) => `${BASE_URL}/api/channel/${id}/tasks`;
export const CHANNEL_MEETINGS = (id) =>
  `${BASE_URL}/api/channel/${id}/meetings`;
export const CHANNEL_INVITATIONS = (id) =>
  `${BASE_URL}/api/channel/${id}/invitations`;
export const PENDING_CHANNEL_INVITATIONS = (channelId, invitationId) =>
  `${BASE_URL}/api/channel/${channelId}/invitations/${invitationId}`;
export const CHANNEL_DOCUMENTS = (id) =>
  `${BASE_URL}/api/channel/${id}/documents`;
export const CHANNEL_DOCUMENT = (channelId, documentId) =>
    `${BASE_URL}/api/channel/${channelId}/document/${documentId}`;
export const DOWNLOAD_CHANNEL_DOCUMENT = (channelId, documentId) =>
    `${BASE_URL}/api/channel/${channelId}/download-document/${documentId}`;
export const CHANNEL_MEETING = (id) => `${BASE_URL}/api/channel/${id}/meeting`;
export const CHANNEL_INVITES = (id) => `${BASE_URL}/api/channel/${id}/invite`;
export const CHANNEL_SIGN_REQUEST = (id) =>
  `${BASE_URL}/api/channel/${id}/sign-request`;
export const CHANNEL_ARCHIVE = (id) => `${BASE_URL}/api/channel/${id}/archive`;
export const CHANNEL_UNARCHIVE = (id) => `${BASE_URL}/api/channel/${id}/unarchive`;

export const INSTANT_SIGN_REQUEST = `${BASE_URL}/api/instant/sign-request`;
export const INSTANT_IN_PERSON_SIGN_REQUEST = `${BASE_URL}/api/instant/in-person`;

//Tasks
export const USER_TASKS = `${BASE_URL}/api/user/tasks`;
export const TASK = (id) => `${BASE_URL}/api/tasks/${id}`;
export const IS_TASK_READY = (id) => `${BASE_URL}/api/tasks/${id}/is-ready`;

//Task messages
export const UPLOAD_TASK_FILES = (id) => `${BASE_URL}/api/tasks/${id}/files`;
export const GET_TASK_FILES = (id) => `${BASE_URL}/api/tasks/${id}/files`;
export const SEND_TASK_MESSAGE = (id) => `${BASE_URL}/api/tasks/${id}/comment`;
export const TASK_DOCUMENT = (taskId, documentId) => `${BASE_URL}/api/tasks/${taskId}/document/${documentId}`;
export const DOWNLOAD_TASK_DOCUMENT = (taskId, documentId) => `${BASE_URL}/api/tasks/${taskId}/download-document/${documentId}`;

//Meetings
export const USER_UPCOMING_MEETINGS = `${BASE_URL}/api/user/meetings`;

export const USER_FIREBASE_TOKEN = `${BASE_URL}/api/user/firebase`;

//Invitations
export const USER_INVITATIONS = `${BASE_URL}/api/invitations`;
export const ACCEPT_INVITATION = (id) =>
  `${BASE_URL}/api/invitations/${id}/accept`;

//Organizations
export const USER_ORGANIZATIONS = `${BASE_URL}/api/user/organizations`;
export const ORGANIZATIONS = `${BASE_URL}/api/organizations`;
export const ORGANIZATION = (id) => `${BASE_URL}/api/organizations/${id}`;
export const ORGANIZATION_MEMBERS = (id) =>
  `${BASE_URL}/api/organizations/${id}/members`;
export const ORGANIZATION_MEMBERS_INVITE = (id) =>
  `${BASE_URL}/api/organizations/${id}/invite`;
export const ORGANIZATION_INVITATIONS = (id) =>
  `${BASE_URL}/api/organizations/${id}/invitations`;
export const PENDING_ORGANIZATION_INVITATIONS = (
  organizationId,
  invitationId,
) =>
  `${BASE_URL}/api/organizations/${organizationId}/invitations/${invitationId}`;
export const ORGANIZATION_MEMBER = (organizationId, memberId) =>
  `${BASE_URL}/api/organizations/${organizationId}/members/${memberId}`;
export const IMANAGE_REDIRECT = `${BASE_URL}/imanage/redirect`;
export const IMANAGE_AUTHORIZED = `${BASE_URL}/imanage/authorized`;
export const IMANAGE_DIALOG_TOKEN = `${BASE_URL}/imanage/dialog-token`;
export const IMANAGE_USER = `${BASE_URL}/imanage/user`;
export const IMANAGE_DOCUMENT_DOWNLOAD = `${BASE_URL}/imanage/document/download`;

// ajax
export const verifyToken = async (token) => {
  try {
    const res = await axios.get(USER_URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res?.data;
  } catch (er) {
    return false;
  }
};
