import React from 'react';
import {render} from 'react-dom';
import Root from './Root';
import './index.css';

if (['sandbox', 'production'].includes(process.env.NAME)) {
  import('@sentry/react').then(async (Sentry) => {
    const {BrowserTracing} = await import('@sentry/tracing');

    Sentry.init({
      dsn: 'https://559aacd9255746f5bd32b19c9c60533b@o4504129104052224.ingest.sentry.io/4504129104969728',
      integrations: [new BrowserTracing()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
      environment: process.env.NAME || 'local'
    });
  });
}

render(<Root/>, document.getElementById('root'));
