import { css } from 'styled-components';

export default css`
  :root {
    --bg: #fbfbfd;
    --primary: #3e3f42;
    --logo-size: 80px;
    --max-width: 1100px;

    --header: 90px;
    --header-bg: #fff;

    --border-color: #eaedf3;
    --fade: #9ea0a5;
    --white-fade: rgb(255, 255, 255, 0.8);
    --blue: #1665d8;
    --orange: #e1532a;
    --input-font-size: 14px;
    --gap: 38px;

    --button-primary: rgb(242, 142, 74);
    --button-primary-hover: rgba(242, 142, 74, 0.8);
    --button-primary-disabled: rgba(242, 142, 74, 0.4);
    --button-primary-disabled-color: #fff;

    --button-secondary: rgb(31, 166, 241);
    --button-secondary-hover: rgba(31, 166, 241, 0.8);
    --button-secondary-disabled: rgba(31, 166, 241, 0.4);
    --button-secondary-disabled-color: #fff;

    --button-blue: rgb(20, 97, 210);
    --button-blue-hover: rgba(20, 97, 210, 0.8);
    --button-blue-disabled: rgba(20, 97, 210, 0.4);
    --button-blue-disabled-color: rgba(20, 97, 210, 0.4);

    --button-red: rgb(225, 83, 42);
    --button-red-hover: rgba(225, 83, 42, 0.8);
    --button-red-disabled: rgba(225, 83, 42, 0.4);
    --button-red-disabled-color: rgba(20, 97, 210, 0.4);

    --button-transparent: transparent;
    --button-transparent-hover: transparent;
    --button-transparent-disabled: transparent;
    --button-transparent-disabled-color: transparent;

    --button-cancel: linear-gradient(0deg, #f6f7f9 0%, #ffffff 100%);
    --button-cancel-hover: linear-gradient(0deg, #f6f7f9 0%, #ffffff 100%);

    --content-top: 20px;
    --content-top-mobile: 35px;
    --shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
  }
`;
