import React, {lazy, Suspense} from 'react';
import {store} from 'root/store';
import CSS from 'root/style';
import i18n from 'root/i18n';

const App = lazy(() => import('./App'));

const I18nextProvider = lazy(() =>
  import('react-i18next').then(module => {
    return {default: module.I18nextProvider};
  })
);

const Provider = lazy(() =>
  import('react-redux').then(module => {
    return {default: module.Provider};
  })
);

const Root = () => {
  return (
    <Suspense fallback={
      <div className="loader">
        <div className="lds-ripple">
          <div></div>
          <div></div>
        </div>
      </div>}>
      <Provider store={store}>
        <CSS/>
        <I18nextProvider i18n={i18n}>
          <App/>
        </I18nextProvider>
      </Provider>
    </Suspense>
  );
};

export default Root;
