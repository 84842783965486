import { css } from 'styled-components';

export default css`

  .flex {
    display: flex;
  }

  .text-center {
    text-align: center;
  }

  .headline {
    font-weight: 500;
    font-size: 24px;
  }

  .justify-between {
    justify-content: space-between;
  }

  .justify-evenly {
    justify-content: space-evenly;
  }

  .justify-center {
    justify-content: center;
  }

  .justify-end {
    justify-content: flex-end;
  }

  .align-center {
    align-items: center;
  }

  .align-start {
    align-items: flex-start
  }

  .direction-column {
    flex-direction: column;
  }

  .no-decoration {
    text-decoration: none;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .m-auto {
    margin: auto;
  }

  .ml-auto {
    margin-left: auto;
  }

  .mt-auto {
    margin-top: auto;
  }

  .mx-auto {
    margin-right: auto;
    margin-left: auto;
  }

  .vh-center {
    transform: translate(-50%, -50%);
    top: 50%;
    position: absolute;
    left: 50%;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .relative {
    position: relative;
  }

  @media (min-width: 768px) {
    .sm\\:direction-row {
      flex-direction: row;
    }

    .sm\\:align-center {
      align-items: center;
    }
  }

  @media (min-width: 992px) {
    .md\\:direction-row {
      flex-direction: row;
    }
  }
`;
