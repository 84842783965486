import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { rootReducer } from './root-reducer';


const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: window.devToolsExtension ? window.devToolsExtension() : (f) => f,
  middleware: customizedMiddleware,
});
