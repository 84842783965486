import i18n from 'i18next';
import translationEN from './translations/en-us.json';
import translationHE from './translations/he.json';

import {initReactI18next} from 'react-i18next';
import axios from 'axios';
import {USER_COUNTRY} from 'root/api';

const resources = {
  'en-US': {
    translation: translationEN,
  },
  'he': {
    translation: translationHE,
  },
};

const languageDetector = {
  type: 'languageDetector',
  async: true, // flags below detection to be async
  detect: callback => {
    const inStorageValue = localStorage.getItem('languageCode');
    if (inStorageValue && ['en-US', 'he'].includes(inStorageValue)) {
      return callback(inStorageValue);
    }

    axios.get(USER_COUNTRY).then(({data: {country}}) => {
      if (country) {
        return callback(country === 'IL' ? 'he' : 'en-US');
      }

      return callback('en-US');
    }).catch(() => {
      return callback('en-US');
    });
  },
  init: () => {
  },
  cacheUserLanguage: (lng) => {
    localStorage.setItem('languageCode', lng);
  },
};

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources,
    load: 'currentOnly',
    fallbackLng: ['en-US', 'he'],
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true,
    }
  });

export default i18n;
