import { createSlice } from '@reduxjs/toolkit';


const storeName = 'channels';

const initialState = {
  tasks: [],
  allChannelsTasksMeta: {},
  tasksPaginationPage: 1,
  isOnlyArchived: false,
};

export const channelsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setTasks(state, action) {
      state.tasks = action.payload;
    },
    setAllChannelsTasksMeta(state, action) {
      state.allChannelsTasksMeta = action.payload;
    },
    setTasksPaginationPage(state, action) {
      state.tasksPaginationPage = action.payload;
    },
    setIsOnlyArchived(state, action) {
      state.isOnlyArchived = action.payload;
    },
    reset: () => initialState
  },
});

export const channelsActions = {
  setTasks: channelsSlice.actions.setTasks,
  setTasksPaginationPage: channelsSlice.actions.setTasksPaginationPage,
  setAllChannelsTasksMeta: channelsSlice.actions.setAllChannelsTasksMeta,
  setIsOnlyArchived: channelsSlice.actions.setIsOnlyArchived,
  reset: channelsSlice.actions.reset,
};

export const channelsReducer = channelsSlice.reducer;
